/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

'use strict'

import { createApp } from 'vue'
import App from './App.vue'

// Vuestic theming
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'

// Global event bus
import mitt from 'mitt'
const emitter = mitt()

// Amplify imports
import Amplify from 'aws-amplify'

/* ===================================================
                      CONFIGURATION
    You must add your own values here! See the GitHub
    repo for more information. @jbesw
   =================================================== */

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    identityPoolRegion: 'us-east-1',
    userPoolId: 'us-east-1_6MP6QUEey',
    userPoolWebClientId: '41prc9a9v5089omepbmmn0jj46',
    mandatorySignIn: false,
    authenticationFlowType: 'CUSTOM_AUTH',
  }
})

const app = createApp(App)
app.use(VuesticPlugin)

app.config.globalProperties.$appName = 'Barista'

// ** Backend config **
app.config.globalProperties.$region = 'us-east-1'
app.config.globalProperties.$APIurl = 'https://gi97jecqb9.execute-api.us-east-1.amazonaws.com/Prod/'
app.config.globalProperties.$APIconfigURL = 'https://yiodg2eb1k.execute-api.us-east-1.amazonaws.com/Prod'
app.config.globalProperties.$ConfigEndpoint = 'https://yiodg2eb1k.execute-api.us-east-1.amazonaws.com/Prod/config'
app.config.globalProperties.$orderManagerEndpoint = 'https://gi97jecqb9.execute-api.us-east-1.amazonaws.com/Prod/'

// ** Websocket connection **
//  PoolId: Retrieve this with the CLI command: aws cognito-identity list-identity-pools --max-results 10 --region <<REGION>>
app.config.globalProperties.$poolId = 'us-east-1:4fe53d33-f1dc-42fb-9de5-1bdb8e8b27fe' // 'YourCognitoIdentityPoolId'
//  IoTendpoint: Retrieve this with the CLI command: aws iot describe-endpoint --endpoint-type iot:Data-ATS --region us-west-2
app.config.globalProperties.$host = 'anput1xffmgcz-ats.iot.us-east-1.amazonaws.com' // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'

app.config.globalProperties.emitter = emitter
app.config.globalProperties.$adminApp = true
app.mount('#app')